import { Link } from "gatsby"
import React from "react"
import tw, { styled } from "twin.macro"
import { css } from "styled-components"
import { H3, P } from "../typography/Typography"
import Image, { ImageType } from "../general/Image"
import { MultilinkStoryblok } from "../../../component-types-sb"
import { cva } from "../../utils/cva"
import LinkResolver from "./Link"

interface CardContainerProps {
  size?: "default" | "wide"
  href?: string
  children?: React.ReactNode
  minheight?: boolean
  url?: MultilinkStoryblok
}

const cardContainerStyles = cva(
  tw`
    p-4 rounded-[12.5px] bg-white-blue-2
  `, {
    variants: {
      isLink: {
        false: null,
        true: tw`hover:(shadow scale-small) duration-200`,
      },
      minheight: {
        false: null,
        true: tw`h-[min-content]  [[data-stretch-items='true'] &]:h-full`,
      },
      size: {
        default: tw`flex flex-col gap-3`,
        wide: tw`
          grid grid-cols-1 grid-rows-1 col-span-full gap-8
          laptop:col-span-2
          desktop:(col-span-3 grid-cols-2 p-8)
          [*>p]:(text-22 mt-4)
        `,
      },
    },
  },
)

export const CardContainer = ({ size = "default", href, minheight, children, url }: CardContainerProps) => {
  if (href) {
    return <Link className="group" to={href} css={cardContainerStyles({ isLink: true, minheight, size })}>
      {children}
    </Link>
  }

  if (url) {
    return <LinkResolver className="group" url={url} css={cardContainerStyles({ isLink: true, minheight, size })}>
      {children}
    </LinkResolver>
  }

  return <div className="group card-large" css={cardContainerStyles({ isLink: false, minheight, size })}>
    {children}
  </div>
}

export const CardTitle = styled(H3)<{ size?: "default" | "medium" | "small" | "large" }>(() => [
  tw`text-24 font-medium my-0`,
  ({ size }) => size === "small" && tw`text-15 leading-normal font-sans`
    || size === "medium" && tw`text-20 leading-normal`
    || size === "large" && tw`text-24 laptop:(text-35)`,
])

export const CardSubtitle = tw.span`text-14 font-bold text-black/50`

export const CardText = styled(P)<{ size?: "default" | "large" }>(() => [
  tw`text-16 font-normal mb-0 font-heading`,
  ({ size }) => size === "large" && tw`font-normal mt-4`,
])

export const CardTagContainer = tw.div`flex flex-row gap-2 pb-3 items-center flex-wrap`

interface WrapperProps {
  aspectratio?: "21/9" | "16/9" | "4/3" | "1/1" | "22/10" | "4/5"
}

export const ImageWrapper = styled.div<WrapperProps>(() => [
  tw`relative object-cover overflow-hidden rounded-[7.5px] shadow`,
  ({ aspectratio }) => css`aspect-ratio: ${aspectratio ?? "21/9"};`,
  {
    ".storyblok-image-wrapper": tw`absolute! w-full inset-0`,
  },
])

interface CardImageProps {
  image?: ImageType | string
  aspectratio?: WrapperProps["aspectratio"]
}

export const CardImage = ({ image, aspectratio }: CardImageProps) => (
  <div tw="inset-0 group-hocus:(scale-small)  (transition-card duration-150) rounded-[7.5px] mb-1">
    <ImageWrapper aspectratio={aspectratio}>
      {typeof image === "string" && <img src={image} alt="placeholder" />}
      {typeof image !== "string" && image?.filename
        ? <Image image={image} fluid={820} height="100%" />
        : <img src="https://via.placeholder.com/376x172" alt="placeholder" />}
    </ImageWrapper>
  </div>
)

// Example usage:
//
// <CardContainer key={blogContent._uid} href={["de"].includes(locale)
// ? rewriteSlug(node.full_slug.slice(3))
// : rewriteSlug(node.full_slug)}
// size="default"
// >
// <CardImage image={blogContent.coverImage} aspectratio="21/9" />
// <CardTagContainer>
//     {["Marketing", "Blogs"].map((tag) => (
//       <Tag key={tag} tw="group-hover:shadow duration-150">
//         {tag}
//       </Tag>
//     ))}
// </CardTagContainer>
// <CardSubtitle>{blogContent.datePublished}</CardSubtitle>
// <CardTitle tw="line-clamp-2">{blogContent.title}</CardTitle>
// <CardText>{blogContent.description}</CardText>
// </CardContainer>
