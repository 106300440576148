import React, { CSSProperties, useRef, useState } from "react"
import SbEditable from "storyblok-react"
import {
  faCircleCheck,
  faCircleXmark,
  faChevronRight,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tw, { styled } from "twin.macro"
import { css } from "styled-components"
import { m } from "framer-motion"
import { CompanyStoryblok, ComparsionTableStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Image from "../general/Image"
import useLocale from "../../hooks/useLocale"
import useGoogleSheets from "../../hooks/useGoogleSheets"
import Tooltip from "../general/Tooltip"
import { P } from "../typography/Typography"
import Spinner from "../general/Spinner"
import LinkResolver from "../general/Link"

interface Props {
  readonly blok: ComparsionTableStoryblok
}

interface Globals {
  update_table_link_text: string
  companies: {
    slug: string
    content: CompanyStoryblok
  }[]
  table_updated_text: string
}

interface TBody {
  _uid: string
  body: {
    _uid: string
    value: string
  }[]
}

interface SortedArray {
  _uid: string
  value: string
}

const TableRow = styled.div(() => [
  tw`
    grid
    col-start-1 col-end-4
    grid-cols-[auto 80px 80px]
    laptop:(
      grid-cols-[auto repeat(var(--columns), 120px)]
      col-end-[calc(var(--columns) + 2)]
    )
    gap-4
    w-full
    bg-[#F1FBFF]
    rounded-lg
    py-2`,
  () => css`
    // Hide the last column on mobile if there are more than 3 columns
    @media (max-width: 768px) {
      & > * {
        &:nth-child(n+4) {
          display: none;
        }
      }
    };
  `,
])

interface TableGridProps {
  columns: number
}

const TableGrid = styled.section<TableGridProps>(() => [
  tw`grid h-full gap-4 overflow-y-hidden place-items-center `,
  ({ columns }) => css`
    @media (min-width: 768px) {
      grid-template-columns: auto ${"120px ".repeat(columns)};
    };
    grid-template-columns: auto 80px 80px;
  `,
])

const TableContainer = tw.div`
  max-w-container w-full h-full
  bg-gradient-to-b from-[#FFFFFF] to-[#F2FCFF]
  shadow
  p-8
  rounded-lg
  flex flex-col gap-4
  laptop:mt-16 mt-8
`

// Sorts the rows according to an index array but after the first two indexes
// eg sortByIndex([0,1,2,3,4,5], [4,3,2,5]) => [0,1,4,3,2,5]
export const sortByIndex = (array: { _uid: string, value: string }[], indexes: number[]): SortedArray[] => {
  const newArray = [array[0], array[1]]
  indexes.forEach((index) => newArray.push(array[index]))
  return newArray
}

const ComparisonTable: React.FC<Props> = ({ blok }) => {
  const { companies, table_updated_text, update_table_link_text } = useGlobalSettings() as unknown as Globals
  const { locale } = useLocale()
  const { data: comparisonTable, loading, error } = useGoogleSheets(locale)
  const [openCategoryIndex, setOpenCategoryIndex] = useState(0)
  const categoryRef = useRef<HTMLButtonElement[]>([])

  if (loading || !comparisonTable || error) return <Spinner size="large" />

  if (blok.companies.length < 2) return <p>Please select three companies to compare</p>
  const compareCompanies = blok.companies as { slug: string }[]

  const numCompanies = blok.companies.length >= 3
    ? 3
    : 2

  // Reduce method for generating categories
  const pricingRowValues = ["Starts at", "Vanaf", "Beginnt bei"]
  const pricingRow = comparisonTable.tbody.find((row) => pricingRowValues.includes(row.body[0].value))

  let currentCatObject = {} as { name: string, rows: TBody[] }
  const newCats = comparisonTable.tbody.reduce((a: { name: string, rows: TBody[] }[], row, index) => {
    if (row.body[1].value === "Category" || row.body[1].value === "Kategorie") {
      // Push the previous category object if it's defined
      if (typeof currentCatObject.name !== "undefined") a.push(currentCatObject)
      // Create a new category object with the new category name
      currentCatObject = { name: row.body[0].value, rows: [] }
    } else if (!pricingRowValues.includes(row.body[0].value)) {
      currentCatObject.rows.push(row)
    }
    // On the last index, add the final category
    if (index === comparisonTable.tbody.length - 1) a.push(currentCatObject)
    return a
  }, [])

  const indexes = [...Array(numCompanies).keys()].map((i) => (
    comparisonTable.thead.findIndex((x) => x.value === compareCompanies[i].slug)))

  const handleOpenCategory = (index: number) => {
    if (index === openCategoryIndex) {
      setOpenCategoryIndex(-1)
    } else {
      setOpenCategoryIndex(index)
      if (categoryRef.current[index]) {
        // wait for the animation to finish, then scroll to the element
        setTimeout(() => scroll({ behavior: "smooth", top: categoryRef.current[index].offsetTop - 48 }), 300)
      }
    }
  }

  const renderCompanyNameOrLogo = (companyName: SortedArray) => {
    if (!companyName) return <div tw="text-error-35">Company not found</div>
    const company = companies.find((x) => x.slug === companyName.value)
    if (!company) return <div tw="text-error-35">{companyName.value} not found</div>

    return companyName.value === "webinargeek"
      ? (
        <Image image={company.content.logo} useRegularImageTag width="144px" />
      )
      : (
        <P tw="font-bold text-24 mb-0 text-gray-1">
          {companyName.value.charAt(0).toUpperCase() + companyName.value.slice(1)}
        </P>
      )
  }

  const sortedCompanies = sortByIndex(comparisonTable.thead, indexes)
  const companyName = sortedCompanies

  return <SbEditable content={blok}>
    <TableContainer>
      <div tw="flex items-center justify-center laptop:flex-row flex-col">
        {/* Header images, WebinarGeek logo vs other company logo */}
        {renderCompanyNameOrLogo(companyName[2])}
        <P tw="mb-0 pr-4 pl-2 text-16 font-normal italic">VS</P>
        {renderCompanyNameOrLogo(companyName[3])}
      </div>
      <TableGrid tw="gap-y-0" columns={numCompanies}>
        <TableRow
          key={1}
          tw="bg-white mt-3"
          style={{ "--columns": numCompanies } as CSSProperties}
        >
          <span />
          {sortByIndex(comparisonTable.thead, indexes).map((item, j) => {
            if (j < 2 || j > numCompanies + 1) return null
            const company = companies.find((x) => x.slug === item.value)
            if (company) {
              return <div tw="px-3 flex items-center justify-center last:hidden laptop:last:flex" key={item._uid}>
                {item.value === "webinargeek"
                  ? <Image image={company.content.logo} useRegularImageTag width="144px" />
                  : <P tw="font-bold text-14 mb-0 text-gray-1">
                    {item.value.charAt(0).toUpperCase() + item.value.slice(1)}
                  </P>}
              </div>
            }
            return <div key={item._uid}>{item.value} not found</div>
          })}
        </TableRow>
        {pricingRow && <TableRow
          key={pricingRow._uid}
          tw="bg-[#DCF5FD]"
          style={{ "--columns": numCompanies } as CSSProperties}
        >
          <span />
          {Array(numCompanies).fill(0)
            .map((_, i) => <span key={i} tw="text-center last:hidden laptop:last:block">
              <span tw="font-medium">{pricingRow.body[indexes[i]].value}</span>/mo
            </span>)}
        </TableRow>}
      </TableGrid>
      {newCats.map((cat, i) => <div key={`${cat.name}`}>
        <button
          onClick={() => handleOpenCategory(i)}
          ref={(el) => {
            if (el) categoryRef.current[i] = el
          }}
          tw="flex justify-between items-center bg-[#DCF5FD] rounded-lg p-2 w-full pr-3"
        >
          <p tw="font-medium">{cat.name}</p>
          <m.div animate={{
            rotate: openCategoryIndex === i
              ? 90
              : 0,
          }}>
            <FontAwesomeIcon icon={faChevronRight} />
          </m.div>
        </button>
        <m.div animate={{
          height: openCategoryIndex === i
            ? "auto"
            : "0",
        }}>
          <TableGrid tw="pt-4" columns={numCompanies}>
            {cat.rows.map((row) => <TableRow
              key={row._uid}
              style={{ "--columns": numCompanies } as CSSProperties}
            >
              <span tw="pl-2 flex items-center gap-2 ">
                <span tw="line-clamp-1">
                  {row.body[0].value}
                </span>
                {row.body[1].value !== "" && <div tw="laptop:block hidden">
                  <Tooltip tooltip={row.body[1].value} noMargin />
                </div>}
              </span>
              {indexes.map((index) => {
                const cellValue = row.body[index].value
                if (cellValue.includes("TRUE")) {
                  return <FontAwesomeIcon
                    tw="text-primary-60 m-auto"
                    icon={faCircleCheck}
                    key={`col1-${row.body[index]._uid}`}
                  />
                }
                if (cellValue === "FALSE") {
                  return <FontAwesomeIcon
                    tw="text-surface-dim m-auto"
                    icon={faCircleXmark}
                    key={`col1-${row.body[index]._uid}`}
                  />
                }
                if ((/\d/u).test(cellValue) || cellValue.includes("Unlimited") || cellValue.includes("Unbegrenzt")) {
                  return <span tw="m-auto text-12" key={`col1-${row.body[index]._uid}`}>
                    {cellValue}
                  </span>
                }
                if (!cellValue) {
                  return <span tw="text-gray-4 m-auto" key={`col1-${row.body[index]._uid}`}>
                    N/A
                  </span>
                }
                return <span tw="text-gray-4 m-auto" key={`col1-${row.body[index]._uid}`}>
                  <Tooltip tooltip={cellValue} noMargin comparisonInfo>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </span>
              })}
            </TableRow>)}
          </TableGrid>
        </m.div>
      </div>)}
      <p tw="text-center">{table_updated_text}</p>
      <LinkResolver styling="none" url={{ linktype: "url", url: "openIntercom" }}>
        <span tw="underline font-light">{update_table_link_text}</span>
      </LinkResolver>
    </TableContainer>
  </SbEditable>
}

export default ComparisonTable
