import React from "react"
import * as Sentry from "@sentry/browser"
import { SbEditableContent } from "storyblok-react"
import CardGrid from "../section/CardGrid"
import SingleImage from "../section/SingleImage"
import LogoBar from "../section/LogoBar"
import Hero from "../section/Hero"
import PricingBlocks from "../section/PricingBlocks"
import ContentCard from "../cards/ContentCard"
import ButtonGroup from "../section/ButtonGroup"
import ImageText from "../section/ImageText"
import CustomerSuccessStoryCard from "../section/CustomerSuccessStoryCard"
import TextColumns from "../section/TextColumns"
import PersonCard from "../cards/PersonCard"
import Carousel from "../section/Carousel"
import FeatureList from "../section/FeatureList"
import AcademyCard from "../cards/AcademyCard"
import VideoCardEditable from "../cards/VideoCard"
import Channel from "../section/Channel"
import TabbedContent from "../section/TabbedContent"
import ComparisionCard from "../cards/ComparisionCard"
import ComparisonTable from "../section/ComparisonTable"
import JobCard from "../cards/JobCard"
import PricingCard from "../cards/PricingCard"
import PricingInfo from "../section/PricingInfo"
import PersonCarousel from "../section/PersonCarousel"
import ReviewGrid from "../section/ReviewGrid"
import Accordion from "../section/Accordion"
import IntegrationCards from "../section/IntegrationCards"
import OpenIntercomCard from "../section/OpenIntercomCard"
import FeatureGrid from "../section/FeatureGrid"
import AllCustomerSuccessStories from "../section/AllCustomerSuccessStories"
import ChallengeCards from "../section/ChallengeCards"
import RegisterFormComponent from "../section/RegisterFormComponent"
import VideoGrid from "../section/VideoGrid"
import PersonGrid from "../section/PersonGrid"
import ProductCards from "../section/ProductCards"
import AcademyGrid from "../section/AcademyGrid"
import LargeRegisterForm from "../section/LargeRegisterForm"
import IntegrationCard from "../cards/IntegrationCard"
import Zapierembed from "../section/ZapierEmbed"
import SimpleCard from "../cards/SimpleCard"
import SocialIcons from "../section/SocialIcons"
import TabbedContentNew from "../section/TabbedContentNew"
import PageCard from "../cards/PageCard"
import LandingPageRegisterForm from "../section/LandingPageRegisterForm"
import FeatureFilter from "../section/FeatureFilter"
import ReviewCard from "../section/ReviewCard"
import CustomerSuccessStoryCardNew from "../cards/CustomerSuccessStoryCardNew"
import CustomerSuccessStoriesIndex from "../section/CustomerSuccessStoriesIndex"
import CustomerSuccessStoriesHighlight from "../section/CustomerSuccessStoriesHighlight"
import MediaText from "../section/MediaText"
import IconCircle from "../media/IconCircle"
import MediaImage from "../media/MediaImage"
import FeaturedBlogs from "../section/FeaturedBlogs"
import YoutubeVideo from "../section/YoutubeVideo"
import AcademyGridNew from "../section/AcademyGridNew"
import AcademyTagFilter from "../section/AcademyTagFilter"
import AcademyWrapper from "../section/AcademyWrapper"
import PricingComparisonTable from "../section/PricingComparisonTable"
import Iframe from "../section/Iframe"
import CalendlyEmbed from "../section/CalendlyEmbed"
import NativeForm from "../section/NativeForm"
import MediaVideo from "../media/MediaVideo"
import NativeFormIframe from "../section/NativeFormIframe"
import Cta from "../section/Cta"
import CustomerSuccessStoriesList from "../section/CustomerSuccessStoriesList"
import YoutubeVideoSection from "../section/YoutubeVideoSection"
import FancyHero from "../section/FancyHero"
import IntegrationsSearch from "../section/IntegrationsSearch"
import BadgeBanner from "../section/BadgeBanner"
import ZapierList from "../section/ZapierList"
import ProductCard from "../section/ProductCard"
import ProductSection from "../section/ProductSection"
import ProConList from "../section/ProConList"
import RichtextEditable from "./Richtext"
import WistiaEmbed from "./WistiaEmbed"
import Placeholder from "./Placeholder"
import Text from "./Text"
import FixedGridItem from "./FixedGridItem"
import KeywordDefinition from "./keywordDefinition"
import Icon from "./Icon"
import ProductCarouselFeature from "./ProductCarouselFeature"
import CarouselCards from "./CarouselCards"
import BunnyEmbed from "./BunnyEmbed"
import ModernCard from "./ModernCard"
import CallButton from "./CallButton"
import DemoButton from "./DemoButton"
import TrailButton from "./TrailButton"

const Components = {
  academyCard: AcademyCard,
  academyGrid: AcademyGrid,
  academyGridNew: AcademyGridNew,
  academyTagFilter: AcademyTagFilter,
  academyWrapper: AcademyWrapper,
  accordion: Accordion,
  allCustomerSuccessStories: AllCustomerSuccessStories,
  badgeBanner: BadgeBanner,
  bunnyEmbed: BunnyEmbed,
  buttonGroup: ButtonGroup,
  calendlyEmbed: CalendlyEmbed,
  callButton: CallButton,
  cardGrid: CardGrid,
  carousel: Carousel,
  carouselCards: CarouselCards,
  challengeCards: ChallengeCards,
  channel: Channel,
  comparisonCard: ComparisionCard,
  comparsionTable: ComparisonTable,
  contentCard: ContentCard,
  cta: Cta,
  customerSuccessStoriesHighlight: CustomerSuccessStoriesHighlight,
  customerSuccessStoriesIndex: CustomerSuccessStoriesIndex,
  customerSuccessStoriesList: CustomerSuccessStoriesList,
  customerSuccessStoryCard: CustomerSuccessStoryCard,
  customerSuccessStoryCardNew: CustomerSuccessStoryCardNew,
  demoButton: DemoButton,
  fancyHero: FancyHero,
  featureFilter: FeatureFilter,
  featureGrid: FeatureGrid,
  featureList: FeatureList,
  featuredBlogs: FeaturedBlogs,
  fixedGridItem: FixedGridItem,
  hero: Hero,
  icon: Icon,
  iconCircle: IconCircle,
  iframe: Iframe,
  imageText: ImageText,
  integrationCard: IntegrationCard,
  integrationCards: IntegrationCards,
  integrationsSearch: IntegrationsSearch,
  jobCard: JobCard,
  keywordDefinition: KeywordDefinition,
  landingPageRegisterForm: LandingPageRegisterForm,
  largeRegisterForm: LargeRegisterForm,
  logoBar: LogoBar,
  mediaImage: MediaImage,
  mediaText: MediaText,
  mediaVideo: MediaVideo,
  modernCard: ModernCard,
  nativeForm: NativeForm,
  nativeFormIframe: NativeFormIframe,
  openIntercomCard: OpenIntercomCard,
  pageCard: PageCard,
  personCard: PersonCard,
  personCarousel: PersonCarousel,
  personGrid: PersonGrid,
  pricingBlocks: PricingBlocks,
  pricingCard: PricingCard,
  pricingComparisonTable: PricingComparisonTable,
  pricingInfo: PricingInfo,
  proConList: ProConList,
  productCard: ProductCard,
  productCards: ProductCards,
  productCarouselFeature: ProductCarouselFeature,
  productSection: ProductSection,
  registerFormComponent: RegisterFormComponent,
  reviewCarousel: ReviewCard,
  reviewGrid: ReviewGrid,
  richtext: RichtextEditable,
  simpleCard: SimpleCard,
  singleImage: SingleImage,
  socialIcons: SocialIcons,
  tabbedContent: TabbedContent,
  tabbedContentNew: TabbedContentNew,
  text: Text,
  textColumns: TextColumns,
  trailButton: TrailButton,
  videoCard: VideoCardEditable,
  videoGrid: VideoGrid,
  wistiaEmbed: WistiaEmbed,
  youtubeVideo: YoutubeVideo,
  youtubeVideoSection: YoutubeVideoSection,
  zapierEmbed: Zapierembed,
  zapierList: ZapierList,
}

interface Props {
  readonly blok: SbEditableContent
}

const DynamicComponent: React.FC<Props> = ({ blok }) => {
  if (Object.keys(Components).includes(blok.component)) {
    const Component = Components[blok.component] as React.FC<Props>
    return <Component blok={blok} />
  }

  Sentry.captureMessage(`Blok type "${blok.component}" not defined in DynamicComponent or missing in Components.`)

  return blok.component
    ? <Placeholder componentName={blok.component} />
    : null
}

export default DynamicComponent
