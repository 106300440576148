import SbEditable from "storyblok-react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { PricingComparisonTableStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { A, H4, H6 } from "../typography/Typography"
import Tooltip from "../general/Tooltip"
import Container from "../general/Container"
import DetailsOpenIcon from "../general/DetailsOpenIcon"
import Button from "../general/Button"
import LinkButton from "../general/LinkButton"
import ButtonGroup from "./ButtonGroup"

interface Props {
  readonly blok: PricingComparisonTableStoryblok
}

interface ComparisonTableQuery {
  stories: {
    nodes: {
      uuid: string
      lang: string
      content: string
      field_component: string
    }[]
  }
}

interface TBody {
  _uid: string
  body: {
    _uid: string
    value: string
  }[]
}

interface Table {
  thead: {
    _uid: string
    value: string
  }[]
  tbody: TBody[]
}

const RenderCheck: React.FC<{ readonly value: string }> = ({ value }) => {
  if (value === "TRUE") return <FontAwesomeIcon tw="text-blue" icon={faCheckCircle} />
  if (value === "FALSE") return <FontAwesomeIcon tw="text-gray-3" icon={faTimes} />
  return <>{value}</>
}

const TableWrapper = tw.div`
  mx-auto
  max-w-container-large w-full hidden laptop:block
  font-light
  [&[data-table-open="true"]]:(
    max-height[none]
    // Will inherit extra margin from table rows when closed
    mb-8
  )
  relative
`

const TableCategory = tw.details`
  [&:nth-child(n+2)]:hidden
  [[data-table-open="true"] &:nth-child(n+2)]:block
  not-last:(mb-6)
`

const TableRow = tw.div`
  flex gap-4 items-center
  py-2
  rounded-lg
  [& > *:first-child]:flex-1.5
  [& > * + *]:(
    flex-1 text-center
  )
  even:bg-white-blue
  [&:nth-child(n+10)]:hidden
  [[data-table-open="true"] &:nth-child(n+10)]:flex
`

const CategoryTableRow = tw(TableRow)`
  cursor-pointer
  hover:text-blue
`

const StickyTableRow = tw(TableRow)`
  [[data-table-open="true"] &]:(
    sticky top[70px]
  )
  pt-4 bg-white
  mb-1
  z-10
  // Stops the shadow from the tables rows bleeding outside as it scrolls up
  px-1 -mx-1
`

const ColumnInfo = tw.div`
  py-4
  rounded-t-lg
  flex flex-col items-center
  space-y-2
`

const OpenTableButtonWrapper = tw.div`
  absolute
  background[linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)]
  inset-0
  pb-16
  flex justify-center items-end
  z-10
  -mb-1
`
const RowLink = tw(A)`
  text-black
  hocus:border-b-gray-3 hocus:no-underline hocus:border-solid
  border-b border-b-gray-4 border-dotted
`

const PricingComparisonTable: React.FC<Props> = ({ blok }) => {
  const [isOpen, setIsOpen] = useState(false)
  const data: ComparisonTableQuery = useStaticQuery(graphql`
    query PricingComparisonTableQuery {
      stories: allStoryblokEntry(
        filter: {field_component: {eq: "comparisonTable"}, slug: {eq: "pricing-comparison-table"}}
      ) {
        nodes {
          uuid
          lang
          content
          field_component
        }
      }
    }
  `)
  const { locale } = useLocale()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const story = data.stories.nodes.find((x) => x.lang === locale)!
  const { table: comparisonTable } = JSON.parse(story.content) as { table: Table }

  // Reduce method for generating categories
  let currentCatObject = {} as { name: string, rows: TBody[] }
  const newCats = comparisonTable.tbody.reduce<{ name: string, rows: TBody[] }[]>((a, row, index) => {
    if (row.body[1].value === "Category") {
      // Push the previous category object if it's defined
      if (typeof currentCatObject.name !== "undefined") a.push(currentCatObject)
      // Create a new category object with the new category name
      currentCatObject = { name: row.body[0].value, rows: [] }
    } else {
      // Add the row to the list of rows on the current category
      currentCatObject.rows.push(row)
    }
    // On the last index, add the final category
    if (index === comparisonTable.tbody.length - 1) a.push(currentCatObject)
    return a
  }, [])

  return <SbEditable content={blok}>
    <Container>
      <TableWrapper data-table-open={isOpen}>
        <StickyTableRow>
          {comparisonTable.thead.map((item, i) => {
            if (i === 0) {
              return <div tw="bg-white self-end" key={item.value}>
                <H4 as="span">{item.value}</H4>
                {isOpen && <Button
                  variant="white-no-border"
                  tw="ml-4 underline hover:text-gray-3"
                  onClick={() => setIsOpen(false)}
                >
                  {blok.close_table_text}
                </Button>}
              </div>
            }
            if (i <= (newCats[0].rows[0].body.length === 6
              ? 2
              : 1)) return null
            return <ColumnInfo key={item.value}>
              <H4 as="span" tw="my-0">{item.value}</H4>
              <LinkButton tw="mx-3" button={blok.buttons[i - (newCats[0].rows[0].body.length === 6
                ? 3
                : 2)]} />
            </ColumnInfo>
          })}
        </StickyTableRow>
        <div tw="relative">
          {newCats.map((cat) => <TableCategory key={cat.name} open>
            <CategoryTableRow as="summary" className="peer">
              <div tw="p-2 pl-4 space-x-2 flex items-center">
                <H6 as="span" tw="my-0">{cat.name}</H6>
                <DetailsOpenIcon />
              </div>
              <div />
              <div />
              <div />
            </CategoryTableRow>
            {cat.rows.map((row) => <TableRow key={row._uid} className="table-row">
              {row.body.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return <div tw="py-2 pr-3 pl-4" key={cell.value}>
                    {row.body[2].value
                      ? <RowLink
                        href={row.body[2].value}
                        target={row.body[2].value.includes("webinargeek.com")
                          ? "_self"
                          : "_blank"}>
                        {cell.value}
                      </RowLink>
                      : <span>{cell.value}</span>}
                    {row.body[1].value && <Tooltip tooltip={row.body[1].value} />}
                  </div>
                }
                if (cellIndex <= (row.body.length === 6
                  ? 2
                  : 1)) return null
                return <div tw="py-2 px-1" key={cell._uid}>
                  <RenderCheck value={cell.value} />
                </div>
              })}
            </TableRow>)}
          </TableCategory>)}
          {!isOpen && <OpenTableButtonWrapper>
            <Button variant="orange" size="md" rounded="full" onClick={() => setIsOpen(true)}>
              {blok.show_table_text}
            </Button>
          </OpenTableButtonWrapper>}
        </div>
      </TableWrapper>

      {blok.mobile_button?.[0] && <div tw="block laptop:hidden">
        <ButtonGroup blok={blok.mobile_button[0]} />
      </div>}
    </Container>
  </SbEditable>
}

export default PricingComparisonTable
