import React from "react"
import SbEditable from "storyblok-react"
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tw from "twin.macro"
import { ProConListStoryblok } from "../../../component-types-sb"
import { H3, P } from "../typography/Typography"

interface Props {
  blok: ProConListStoryblok
}

const Container = tw.div`
  w-full max-w-container
  rounded-2xl
  p-8
  bg-white shadow-[0px 4px 8px 0px #00000059]
  flex items-center justify-center flex-col
`

const ProConList: React.FC<Props> = ({ blok }) => {
  const pros = blok.pros.split("\n")
  const cons = blok.cons.split("\n")

  const proscons = pros.map((pro, i) => [pro, cons[i]])

  return (
    <SbEditable content={blok}>
      <div tw="w-full px-6 flex justify-center">
        <Container>
          <H3 tw="mb-2 mt-0">{blok.title}</H3>
          <P tw="mb-8">{blok.subtitle}</P>
          <div tw="flex-col gap-2 laptop:flex hidden">
            {proscons.map(([pro, con]) => (
              <div
                key={pro}
                tw="grid grid-cols-2 gap-16 bg-[#F1FBFF] rounded-full px-4 py-1"
              >
                <span tw="whitespace-nowrap">
                  {pro && (
                    <FontAwesomeIcon
                      tw="text-primary-60 pr-2"
                      icon={faCircleCheck}
                    />
                  )}
                  {pro}
                </span>
                <span tw="whitespace-nowrap">
                  {con && (
                    <FontAwesomeIcon
                      tw="text-error-40 pr-2"
                      icon={faCircleXmark}
                    />
                  )}
                  {con}
                </span>
              </div>
            ))}
          </div>
          <div tw="flex laptop:hidden flex-col gap-2 w-full">
            {pros.map((pro) => (
              <div tw="bg-[#F1FBFF] rounded-full px-4 py-1" key={pro}>
                <span tw="whitespace-nowrap">
                  {pro && (
                    <FontAwesomeIcon
                      tw="text-primary-60 pr-2"
                      icon={faCircleCheck}
                    />
                  )}
                  {pro}
                </span>
              </div>
            ))}
            {cons.map((con) => (
              <div tw="bg-[#F1FBFF] rounded-full px-4 py-1" key={con}>
                <span tw="whitespace-nowrap">
                  {con && (
                    <FontAwesomeIcon
                      tw="text-error-40 pr-2"
                      icon={faCircleXmark}
                    />
                  )}
                  {con}
                </span>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </SbEditable>
  )
}

export default ProConList
