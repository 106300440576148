import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { ImageTextStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Container from "../general/Container"
import Image from "../general/Image"
import { Richtext } from "../general/Richtext"

interface Props {
  readonly blok: ImageTextStoryblok
}

type ImagePosition = "left" | "right"
type Width = "normal" | "wide" | "extra-wide"
type ImagePostitonMobile = "top" | "bottom"
type BoxPosition = "top-left" | "bottom-left" | ""
type BoxColor = "lighter" | "light" | "medium"
type TextImageRatio = "1" | "2" | "0.7"

interface WrapperProps {
  imagePosition: ImagePosition
  width: Width
  imagePositionMobile: ImagePostitonMobile
  textCenter?: boolean
  border?: boolean
}

interface TextWrapperProps {
  textImageRatio: TextImageRatio
  imagePosition: ImagePosition
  width: Width
}

const Wrapper = styled.div<WrapperProps>(({ imagePosition, width, imagePositionMobile, textCenter, border }) => [
  tw`flex my-4 flex-col items-center desktop:(justify-between items-start) w-full`,
  imagePosition === "right" && tw`desktop:(flex-row-reverse)`,
  imagePosition === "left" && tw`desktop:(flex-row)`,
  width === "normal" && tw`max-w-container `,
  width === "wide" && tw`max-w-container-large`,
  width === "extra-wide" && tw`max-w-container-huge`,
  imagePositionMobile === "bottom" && tw`flex-col-reverse`,
  textCenter && tw`desktop:(items-center)`,
  border && tw`bg-blue-midnight py-16 px-12 rounded-lg shadow-2xl text-white`,
])

const TextWrapper = styled.div<TextWrapperProps>(({ textImageRatio, width }) => [
  tw`text-center desktop:(text-left items-start)`,
  textImageRatio === "0.7" && tw`flex-0.7`,
  textImageRatio === "1" && tw`flex-1`,
  textImageRatio === "2" && tw`flex-1.5`,
  width === "wide" && tw`desktop:(maxWidth[475px])`,
  width === "extra-wide" && tw`desktop:(maxWidth[600px])`,
])

const ImageWrapper = styled.div<{ imagePosition: ImagePosition }>(({ imagePosition }) => [
  tw`flex justify-center max-w-full  desktop:(w-4 flex-1)`,
  imagePosition === "right" && tw`desktop:justify-end`,
  imagePosition === "left" && tw`desktop:justify-start`,
])

const BoxWrapper = styled.div<{ boxPosition?: BoxPosition }>(({ boxPosition }) => [
  tw`relative flex max-w-full`,
  boxPosition === "top-left" && tw`tablet:(pt-5 pl-5)`,
  boxPosition === "bottom-left" && tw`tablet:(pb-5 pl-5)`,
])

const Box = styled.div<{ boxPosition?: BoxPosition, boxColor?: BoxColor }>(({ boxPosition, boxColor }) => [
  tw`absolute left-0 hidden w-48 h-40 tablet:block`,
  boxPosition === "top-left" && tw`top-0`,
  boxPosition === "bottom-left" && tw`bottom-0`,
  boxColor === "lighter" && tw`bg-white-blue-2`,
  boxColor === "light" && tw`bg-blue-baby`,
  boxColor === "medium" && tw`bg-blue-light`,
])

const Spacer = styled.div<{ width: Width }>(({ width }) => [
  tw`hidden desktop:block`,
  width === "normal" && tw`w-8`,
  width === "wide" && tw`w-12`,
  width === "extra-wide" && tw`w-12`,
])

const imageMaxSize = {
  normal: 568,
  small: 427,
}

const ImageText: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)
  const maxWidth = blok.image_max_size
    ? imageMaxSize[blok.image_max_size]
    : 900
  return <SbEditable content={blok}>
    <Container>
      <Wrapper
        imagePosition={blok.image_position}
        width={blok.width}
        imagePositionMobile={blok.image_position_mobile}
        textCenter={blok.text_center}
        border={blok.has_border}
      >
        <ImageWrapper imagePosition={blok.image_position}>
          <BoxWrapper boxPosition={blok.box_position}>
            {blok.box_position && <Box boxPosition={blok.box_position} boxColor={blok.box_color} />}
            <Image
              data-rounded={blok.image_style === "rounded"}
              tw="relative [&[data-rounded='true']]:rounded-lg"
              image={blok.image}
              fluid={Math.min(maxWidth, width)}
              {...(width && { width: Math.min(maxWidth, width) })}
            />
          </BoxWrapper>
        </ImageWrapper>
        <Spacer width={blok.width} />
        <TextWrapper
          imagePosition={blok.image_position}
          textImageRatio={blok.text_image_ratio}
          width={blok.width}
          tw="[.section-dark-background &]:text-white"
        >
          <Richtext text={blok.text as DocumentNode} blueHeadings={blok.blue_headings} />
        </TextWrapper>
      </Wrapper>
    </Container>
  </SbEditable>
}

export default ImageText
