import tw, { TwStyle } from "twin.macro"
import { isKeyofObject } from "./typeGuards"

// Influenced by https://cva.style/docs and follows their API as close as possible

type CVAVariantShape = Record<string, Record<string, TwStyle | null>>
type CVAVariantSchema<Variants extends CVAVariantShape> = {
  [Variant in keyof Variants]?: WeakStringToBoolean<keyof Variants[Variant]>
}

/**
 * Builds a collection of cva twin styles to be passed into a css prop
 * > const styles = cva(baseStyle, config);
 * Parameters:
 * * baseStyle: the base classes - tw\`bg-red\`
 * * config:
 * * * variants: your variants schema
 * * * defaultVariants: set default values for previously defined variants
 * * * compoundVariants: set styles when combinations of variants are satisfied at the same time
 */
export const cva
  = <Variants extends CVAVariantShape>(
    baseTwStyles: MaybeArray<TwStyle>,
    config?: {
      variants: Variants
      compoundVariants?: (CVAVariantSchema<Variants> & { twStyles: TwStyle })[]
      defaultVariants?: CVAVariantSchema<Variants>
    },
  ) => (
      props?: CVAVariantSchema<Variants> & { twStyles?: MaybeArray<TwStyle> },
    ): TwStyle[] => {
      const {
        variants = {},
        defaultVariants,
        compoundVariants = [],
      } = config ?? {}
      return [
        ...[baseTwStyles].flat(),
        ...[props?.twStyles ?? []].flat(),
        ...Object.keys(variants).map((variantKey) => {
          const variantValue: string | undefined = String(
            props?.[variantKey] ?? defaultVariants?.[variantKey],
          )
          if (!variantValue || !isKeyofObject(variantKey, variants)) return tw``
          return variants[variantKey][variantValue] ?? tw``
        }),
        ...compoundVariants.map(({ twStyles: styles, ...compoundVariant }) => {
          if (
            Object.keys(compoundVariant).every(
              (variantKey) => props?.[variantKey] === compoundVariant[variantKey],
            )
          ) return styles
          return tw``
        }),
      ]
    }

/**
 * Extracts the variant type from a cva twin style which can be used as react component props
 */
export type VariantProps<
  Component extends (props: CVAVariantSchema<CVAVariantShape>) => unknown,
> = Omit<ExcludeUndefined<Parameters<Component>[0]>, "twStyles">
