import tw from "twin.macro"
import React from "react"
import { A } from "../typography/Typography"
import { isUndefined } from "../../utils/typeGuards"

const TagSpan = tw.span`
  inline-block
  bg-[#0E4D5E]
  text-white text-14
  font-medium
  px-3 py-1.5
  rounded-lg
  h-[min-content]
  w-min
  whitespace-nowrap
  [&[data-background-style~="transparent"]]:(
    bg-opacity-0
    border border-gray-3
  )
`

const TagA = tw(A)`
  bg-[#0E4D5E]
  text-white text-14
  font-medium
  rounded-md
  text-center
  px-3 py-1.5
  min-w-[60px]
  h-[min-content]
  w-min
  [&[data-background-style~="transparent"]]:(
    bg-opacity-0
    border border-gray-3
  )
`

interface TagProps extends React.HTMLAttributes<HTMLElement> {
  readonly href?: string
  readonly children: React.ReactNode
  readonly variant?: "default" | "transparent"
}

const Tag: React.FC<TagProps> = ({ href, children, ...props }) => {
  if (!isUndefined(href)) {
    return <TagA href={href} {...props}>{children}</TagA>
  }
  return <TagSpan {...props}>{children}</TagSpan>
}

export default Tag
