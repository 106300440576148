import tw, { TwStyle } from "twin.macro"
import styled from "styled-components"

export type ButtonVariant = "orange" | "blue" | "white" | "white-blue" | "text" | "white-no-border" | "light-blue"
type ButtonSize = "sm" | "md" | "lg" | "thick" | "tag"
type RoundedStyle = "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full"

interface ButtonProps {
  /**
   * Variant determines the color of the button
   */
  variant?: ButtonVariant
  /**
   * Common fixed sizes of button
   */
  size?: ButtonSize
  /**
   * If true, the button will be rendered as an icon button
   */
  icon?: boolean
  /**
   * Determines the rounded style of the button
   */
  rounded?: RoundedStyle
}

const buttonVariants: Record<ButtonVariant, TwStyle> = {
  blue: tw`font-light text-white! bg-blue! border-blue hocus:shadow-glow-blue`,
  "light-blue": tw`font-light text-gray-1 bg-blue-tulip border-blue-tulip  hocus:shadow-glow-blue`,
  orange: tw`text-white! bg-orange! border-orange hocus:shadow-glow-orange`,
  text: tw``,
  white: tw`font-normal bg-white! border-blue text-gray-1! hocus:(shadow-glow-blue)
  [.section-dark-background &]:border-0`,
  "white-blue": tw`font-light bg-transparent [.section-dark-background &]:bg-white
  border-blue! border-2! text-gray-1! hocus:(shadow-glow-blue bg-blue! text-white! [img]:(invert brightness-0))`,
  "white-no-border": tw`font-normal bg-white! text-gray-1! border-0 hocus:(shadow-glow-white)`,
}

const buttonSizes: Record<ButtonSize, TwStyle> = {
  lg: tw`py-4 px-14 text-20`,
  md: tw`px-6.25 py-2.5 text-16`,
  sm: tw`py-1.875 px-6.25 text-16 font-normal`,
  tag: tw`text-14 py-1.5 h-[min-content] px-4 font-bold bg-[#90C4E0] text-white border-none hocus:shadow-glow-blue`,
  thick: tw`text-18 py-[9.5px] px-8 font-medium`,
}

const buttonIconSizes: Record<ButtonSize, TwStyle> = {
  lg: tw`flex gap-2 pl-10 rounded-full`,
  md: tw`flex gap-2 pl-5 rounded-full`,
  sm: tw`flex gap-2 pl-5 rounded-full`,
  tag: tw`flex gap-2 pl-5 rounded-full`,
  thick: tw`flex gap-2 pl-6 rounded-full`,
}

const roundedStyles: Record<RoundedStyle, TwStyle> = {
  "2xl": tw`rounded-2xl`,
  "3xl": tw`rounded-3xl`,
  full: tw`rounded-full`,
  lg: tw`rounded-lg`,
  md: tw`rounded-md`,
  none: tw`rounded-none`,
  sm: tw`rounded-sm`,
  xl: tw`rounded-xl`,
}

const Button = styled.button<ButtonProps>(() => [
  tw`flex items-center justify-center font-normal text-center (transition-button duration-300)
    ease-in-out border-2 border-solid shadow-none cursor-pointer disabled:opacity-30 font-sans h-[min-content]`,
  ({ variant = "white" }) => buttonVariants[variant],
  ({ size }) => (size
    ? buttonSizes[size]
    : buttonSizes.md),
  ({ rounded }) => (rounded
    ? roundedStyles[rounded]
    : null),
  ({ icon, size }) => icon && size && buttonIconSizes[size],
])

export default Button
