import React from "react"
import SbEditable from "storyblok-react"
import { CompanyStoryblok, ComparisonCardStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { CardContainer } from "../general/Card"

interface Props {
  readonly blok: ComparisonCardStoryblok
}

const ComparisionCard: React.FC<Props> = ({ blok }) => {
  const companies = blok.companies as unknown as { content: CompanyStoryblok }[]
  return <SbEditable content={blok}>
    <CardContainer url={blok.url}>
      <div tw="flex flex-col items-center justify-center">
        {companies.length > 0
          ? <Image image={companies[0].content.logo} fluid={220} width={220} />
          : <p tw="my-10 text-red font-bold">Select a first company</p>
        }
        <div tw="text-20 text-center group-hocus:text-blue italic">VS</div>
        {companies.length > 1
          ? <Image image={companies[1].content.logo} fluid={220} width={220} />
          : <p tw="my-10 text-red font-bold">Select a second company</p>
        }
      </div>
    </CardContainer>
  </SbEditable>
}

export default ComparisionCard
